// Round
@function round-decimal ($number, $digits: 0) {
    @return to-fixed($number, $digits, 'round');
}

// Ceil
@function ceil-decimal ($number, $digits: 0) {
    @return to-fixed($number, $digits, 'ceil');
}

// Floor
@function floor-decimal ($number, $digits: 0) {
    @return to-fixed($number, $digits, 'floor');
}

@function to-fixed ($number, $digits: 0, $round: 'round') {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $round == 'round' {
        @return round($number * $n) / $n;
    } @else if $round == 'ceil' {
        @return ceil($number * $n) / $n;
    } @else if $round == 'floor' {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $round } is undefined keyword.';
        @return $number;
    }
}

@function nounit($value) {
    $_nounit_divisor: (floor($value) * 2) % 2 + 1;
   @return ($value / $_nounit_divisor);
}

@mixin opacity ($alpha: 1) {
  $alphaIE: $alpha * 100;
  filter: alpha(opacity=$alphaIE)!important;
  -moz-opacity: $alpha!important;
  opacity: $alpha!important;
}

@mixin hidetext {
  overflow: hidden;
  text-indent: 120%;
  white-space: nowrap;
}

@mixin overhidetext {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin bg($img) {
  background: url($img) center no-repeat;
  background-size: 100%;
}

@mixin ring($size, $color) {
  border-radius: #{$size/2}px;
  box-shadow: $color 0px 0px 0px #{$size/4}px inset;
  display: inline-block;
  height: #{$size}px;
  width: #{$size}px;
  vertical-align: middle;
}

@function black($opacity: 1) {
  @return rgba(0, 0, 0, $opacity);
}

@function white($opacity: 1) {
  @return rgba(255, 255, 255, $opacity);
}

@mixin ff-g {
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "Helvetica Neue", Helvetica, Arial, Verdana, Roboto, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@mixin ff-m {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin ff-c {
  font-family: 'Cormorant Garamond', serif;
}

@mixin fx-c {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin tb-c {
  display: table;

  div {
    display: table-cell;
    vertical-align: middle;

    div {
      margin-left: auto;
      margin-right: auto;
      text-align:center;
    }
  }
}

@mixin ratio-f ($width: 100%, $height: 100%, $nested: 'div') {
  position: relative;
  width: $width;

  &:before {
    content: "";
    display: block;
    padding-top: $height;
  }

  @at-root #{selector-nest(&, $nested)} {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    @content;
  }
}

@mixin clump($num: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: #{$num};
}

@mixin bg-blur($img: '') {
  @if ($img != '') {
    background-image: url($img);
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(16px);
}


@mixin extend-context($extendee, $extender) {
  @at-root {
    #{selector-extend(&,$extendee, $extender)} {
      @content;
    }
  }
}

@mixin replace-context($original, $replace) {
  @at-root {
    #{selector-replace(&, $original, $replace)} {
      @content;
    }
  }
}

$breakpoint-up: (
  'sm'  : 'screen and (min-width: 480px)',
  'md'  : 'screen and (min-width: 768px)',
  'lg'  : 'screen and (min-width: 1000px)',
  'xl'  : 'screen and (min-width: 1280px)',
  'xxl' : 'screen and (min-width: 1440px)',
  'r2'   : '(-webkit-device-pixel-ratio: 2)',
) !default;

$breakpoint-down: (
  'xs'  : 'screen and (max-width: 320px)',
  'sm'  : 'screen and (max-width: 479px)',
  'md'  : 'screen and (max-width: 767px)',
  'lg'  : 'screen and (max-width: 999px)',
  'xl'  : 'screen and (max-width: 1199px)',
  'xxl' : 'screen and (max-width: 1440px)',
  'r2'   : '(-webkit-device-pixel-ratio: 2)',
) !default;

$l-breakpoint-down: (
  'xs'  : 'screen and (max-height: 480px)',
  'sm'  : 'screen and (max-height: 568px)'
) !default;

@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin lmq-down($breakpoint: md) {
  @media #{map-get($l-breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin mq-r2 () {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin mq-l () {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

@function get-sprite-width ($sprite, $ratio: 1) {
  @return nth($sprite, 5) * $ratio / 2;
}

@function get-sprite-ratio ($sprite) {
  $sprite_h: nounit(nth($sprite, 6));
  $sprite_w: nounit(nth($sprite, 5));
  @return $sprite_h / $sprite_w;
}

// spritesmith
@mixin sprite-width($sprite, $ratio: 1) {
  width: (nth($sprite, 5) * $ratio / 2);
}

@mixin sprite-height($sprite, $ratio: 1) {
  height: (nth($sprite, 6) * $ratio / 2);
}

@mixin sprite-position($sprite, $ratio: 1) {
  $sprite-offset-x: nth($sprite, 3) * $ratio / 2;
  $sprite-offset-y: nth($sprite, 4) * $ratio / 2;
  background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite-size($sprite, $ratio: 1) {
  $sheet-width: floor($spritesheet-width / 2);
  $sheet-height: floor($spritesheet-height / 2);
  background-size: $sheet-width $sheet-height;
}

@mixin sprite($sprite, $ratio: 1) {
  @include sprite-height($sprite, $ratio);
  @include sprite-image($sprite);
  @include sprite-position($sprite, $ratio);
  @include sprite-width($sprite, $ratio);
  @include sprite-size($sprite);
}

@mixin responsive-sprite-position($sprite) {
  $sprite-offset-x: nounit(nth($sprite, 1));
  $sprite-offset-y: nounit(nth($sprite, 2));
  $sheet_w: nounit(nth($sprite, 7));
  $sheet_h: nounit(nth($sprite, 8));
  $sprite_w: nounit(nth($sprite, 5));
  $sprite_h: nounit(nth($sprite, 6));

  background-position: (($sprite-offset-x / ($sheet_w - $sprite_w)) * 100%) (($sprite-offset-y / ($sheet_h - $sprite_h)) * 100%);
}

@mixin responsive-sprite-size($sprite) {
  $sheet-width: nounit(nth($sprite, 7) / nth($sprite, 5)) * 100%;
  $sheet-height: nounit(nth($sprite, 8) / nth($sprite, 6)) * 100%;

  background-size: round-decimal($sheet-width, 3) round-decimal($sheet-height, 3);
}

@mixin responsive-sprite($sprite, $nest: '') {
  $sprite_h: nounit(nth($sprite, 6));
  $sprite_w: nounit(nth($sprite, 5));

  @include sprite-image($sprite);
  @include responsive-sprite-position($sprite);
  @include responsive-sprite-size($sprite);
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: ($sprite_h / $sprite_w * 100%);
  }

  @if ($nest != '') {
    @at-root #{selector-nest(&, $nest)} {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      @content;
    }
  }
}

@mixin grid-child ($col-start, $col-end, $row-start, $row-end) {
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $col-end - $col-start;
  -ms-grid-row: $row-start;
  -ms-grid-row-span: $row-end - $row-start;
  grid-column: #{$col-start}/#{$col-end};
  grid-row: #{$row-start}/#{$row-end};
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

// width: fluid-type(1024px, 1440px, 100px, 120px);
@function fluid-type($min-vw, $max-vw, $min-fz, $max-fz, $lts:1){
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-fz);
  $u4: unit($max-fz);

  $max-fz-px: strip-unit($max-fz);
  @if (unit($max-fz) == 'rem') {
    $max-fz-px: strip-unit($max-fz) * 10;
  }

  $min-fz-px: strip-unit($min-fz);
  @if (unit($min-fz) == 'rem') {
    $min-fz-px: strip-unit($min-fz) * 10;
  }

  $calc: calc((#{$min-fz} + (1vw - #{$min-vw / 100}) * #{100 * ($max-fz-px - $min-fz-px) / strip-unit($max-vw - $min-vw)}) * #{$lts});

  @return $calc;
}

@function fluid-type-h($min-vh, $max-vh, $min-fz, $max-fz, $lts:1){
  $u1: unit($min-vh);
  $u2: unit($max-vh);
  $u3: unit($min-fz);
  $u4: unit($max-fz);

  $max-fz-px: strip-unit($max-fz);
  @if (unit($max-fz) == 'rem') {
    $max-fz-px: strip-unit($max-fz) * 10;
  }

  $min-fz-px: strip-unit($min-fz);
  @if (unit($min-fz) == 'rem') {
    $min-fz-px: strip-unit($min-fz) * 10;
  }

  $calc: calc((#{$min-fz} + (1vh - #{$min-vh / 100}) * #{100 * ($max-fz-px - $min-fz-px) / strip-unit($max-vh - $min-vh)}) * #{$lts});

  @return $calc;
}

// @mixin fluid-type($min-vw, $max-vw, $min-fz, $max-fz, $lts:0.06){
//   $u1: unit($min-vw);
//   $u2: unit($max-vw);
//   $u3: unit($min-fz);
//   $u4: unit($max-fz);

//   $max-fz-px: strip-unit($max-fz);
//   @if (unit($max-fz) == 'rem') {
//     $max-fz-px: strip-unit($max-fz) * 10;
//   }

//   $min-fz-px: strip-unit($min-fz);
//   @if (unit($min-fz) == 'rem') {
//     $min-fz-px: strip-unit($min-fz) * 10;
//   }

//   & {
//     font-size: calc(#{$min-fz} + (1vw - #{$min-vw / 100}) * #{100 * ($max-fz-px - $min-fz-px) / strip-unit($max-vw - $min-vw)});
//     letter-spacing: calc((#{$min-fz} + (1vw - #{$min-vw / 100}) * #{100 * ($max-fz-px - $min-fz-px) / strip-unit($max-vw - $min-vw)}) * #{$lts});
//   }
// }

// @mixin fluid-type-h($min-vw, $max-vw, $min-fz, $max-fz, $lts:0.06){
//   $u1: unit($min-vw);
//   $u2: unit($max-vw);
//   $u3: unit($min-fz);
//   $u4: unit($max-fz);

//   $max-fz-px: strip-unit($max-fz);
//   @if (unit($max-fz) == 'rem') {
//     $max-fz-px: strip-unit($max-fz) * 10;
//   }

//   $min-fz-px: strip-unit($min-fz);
//   @if (unit($min-fz) == 'rem') {
//     $min-fz-px: strip-unit($min-fz) * 10;
//   }

//   & {
//     font-size: calc(#{$min-fz} + (1vh - #{$min-vw / 100}) * #{100 * ($max-fz-px - $min-fz-px) / strip-unit($max-vw - $min-vw)});
//     letter-spacing: calc((#{$min-fz} + (1vw - #{$min-vw / 100}) * #{100 * ($max-fz-px - $min-fz-px) / strip-unit($max-vw - $min-vw)}) * #{$lts});
//   }
// }

@mixin fix-letter-spacing ($fz: 1.4rem, $lts: 0.06) {
  font-size: $fz;
  letter-spacing: ($fz * $lts);
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin scroll-animation($transform: translateY(20%), $duration: 1.2s, $delay: .3s, $num: 100) {
  opacity: 0;
  transform: $transform;
  visibility: hidden;
  @for $i from 1 through $num {
    $delay-calc: $delay * ($i - 1);
    &:nth-of-type(#{$i}){
      transition: opacity #{$duration} ease #{$delay-calc}, visibility #{$duration} ease #{$delay-calc}, transform #{$duration} ease #{$delay-calc};
    }
  }
}

@mixin btn-line-animation-delay($time: 0s) {
  $btn-line-delay: (0, .5s, .7s, 1s);

  & > .-top::after{
    transition-delay: nth($btn-line-delay, 1) + $time;
  }
  & > .-right::after{
    transition-delay: nth($btn-line-delay, 2) + $time;
  }
  & > .-bottom::after{
    transition-delay: nth($btn-line-delay, 3) + $time;
  }
  & > .-left::after{
    transition-delay: nth($btn-line-delay, 4) + $time;
  }
}

@mixin watch-animation($name, $display: false, $count: 1, $delay: 0.1s, $start: 0s, $duration: 1.2s){
  animation-duration: #{$duration};
  animation-fill-mode: both;
  animation-name: #{$name};
  @if ($display == true){
    display: inline-block;
  }

  @if ($count > 1){
    @for $i from 1 through $count {
      &:nth-of-type(#{$i}) {
        animation-delay: #{$start + $delay * ($i - 1)};
      }
    }
  } @else {
    animation-delay: #{$delay};
  }
}