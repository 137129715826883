.nav{
  height: 100vh;
  width: 10rem;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  @include mq-down(lg) {
    width: 6rem;
    height: 6rem;
    @include mq-down(lg) {
      @keyframes sp-nav-btn {
        0% {
          opacity: 0;
          visibility: hidden;
          transform: translate(60px, -60px);
        }
        90% {
          opacity: 0;
          visibility: hidden;
          transform: translate(60px, -60px);
        }
        100% {
          opacity: 1;
          visibility: visible;
          transform: translate(0, 0);
        }
      }
      animation: sp-nav-btn 3.5s;
    }
  }
  &_btn_wrap{
    position: relative;
    @keyframes nav-btn {
      0% {
        opacity: 0;
        // transform: scale(0.75);
      }
      75% {
        opacity: 0;
        // transform: scale(1.25);
      }
      100% {
        opacity: 1;
        // transform: scale(1);
      }
    }
    animation: nav-btn 4s;

    @include mq-down(lg) {
      @keyframes nav-btn {
        0% {
          opacity: 1;
          // transform: scale(0.75);
        }
        75% {
          opacity: 1;
          // transform: scale(1.25);
        }
        100% {
          opacity: 1;
          // transform: scale(1);
        }
      }
      animation: nav-btn 0s;
    }
  }

  &_btn{
    margin: 0 auto 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    overflow: hidden;
    @include mq-down(lg) {
      width: 2.8rem;
      height: 2.8rem;
    }

    &_caption{
      &:before{
        display: inline;
        content: "Contact";
      }
      &:after{
        display: none;
        content: "Close";
      }
      &.-is-active{
        &:before{
          display: none;
        }
        &:after{
          display: inline;
        }
      }

      @include fix-letter-spacing(1.4rem, 0.08);
      color: $key-color;
      text-align: center;
      @include mq-down(lg) {
        @include fix-letter-spacing(1rem, 0.08);
      }
    }

    svg{
      opacity: 1;
      visibility: visible;
      width: 100%;
      height: auto;
      fill: $key-color;
      transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    }

    .close{
      opacity: 0;
      visibility: hidden;

      width: 2.8rem;
      height: 2.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -14px;
      margin-top: -19px;

      .btn_line{
        position: relative;
        display: block;
        width: 100%;
        height: .1rem;
        margin-bottom: 1rem;
        transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
        background-color: $key-color;
        &:first-of-type{
          transform: translate(0, 1.1rem) rotate(45deg);
        }
        &:last-of-type{
          transform: translate(0, 0rem) rotate(-45deg);
        }
        &:after{
          content: '';
          position: absolute;
          display: block;
          transition: transform;
          background-color: $key-color;
        }
      }
    }

    &.-is-active{
      svg{
        opacity: 0;
        visibility: hidden;
      }
      .close{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.nav_unshown {
  display:none;
}

#nav_content{
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateX(105%);
  transform: translateX(105%);
  z-index: 999;
  transition: all 500ms cubic-bezier(0.015, 0.975, 0.595, 0.990);
}

#nav_open:checked ~ #nav_content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  box-shadow: 6px 0 25px rgba(0,0,0,.15);
}