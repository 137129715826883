@import "mixin";
@import "variable";
@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,300i,400,400i,500,500i,600,600i,700,700i');

html {
  font-size: 62.5%;
  overflow-x: hidden !important;
}

body {
  @include ff-m;
  background-color: $body-color;
  color: $text;
  font-feature-settings : "palt";
  font-size: 1.6em;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.25;
  touch-callout: none;
  overflow: hidden;
}

// safariでtransition時に文字が細くなるバグの対処
_::-webkit-full-page-media, _:future, :root body {
  -webkit-font-smoothing: antialiased;
}

div {
  display: block;
}

img {
  border: none;
  user-select: none;
}

li {
  list-style: none;
}

input[type=radio], input[type="checkbox"], select {
  appearance: none;

  &:focus {
    outline: none;
  }
}

button {
  @include ff-g;
  -webkit-tap-highlight-color: transparent;
  appearance: none;

  &:focus, &:active {
    outline: none;
  }
}

textarea {
  resize: none;
}

h1, h2, h3, h4, h5, ul, ol, p, dl, dt, dd {
  margin: 0;
  padding: 0;
}

a {
  -webkit-tap-highlight-color: transparent;
  color: $text;

  &:hover, &:active {
    text-decoration: none;
  }
}

figure{
  margin: 0;
  padding: 0;
}

.link-block {
  display: block;
  height: 100%;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover, &:active {
    text-decoration: none;
  }
}

.fw-n {
  font-weight: normal;
}

.fw-b {
  font-weight: bold;
}

$f: 12 14 18 24 30 36 60 64 80;

@each $value in $f {
  $key: index($f, $value);
  .fz-#{nth($f, $key)} { font-size: #{nth($f, $key)/10}rem; }
}

$m: 0 .1 .2 .3 .4 .8 1 1.2 1.4 1.6 1.8 2 2.4 2.8 3.2 4 4.8 5.6 6.4 7.2 8 8.8 9.6;

@each $value in $m {
  $key: index($m, $value);
  .mt-#{nth($m, $key) * 10} { margin-top: #{nth($m, $key)}rem; }
  .ml-#{nth($m, $key) * 10} { margin-left: #{nth($m, $key)}rem; }
  .mr-#{nth($m, $key) * 10} { margin-right: #{nth($m, $key)}rem; }
  .mb-#{nth($m, $key) * 10} { margin-bottom: #{nth($m, $key)}rem; }
  .pt-#{nth($m, $key) * 10} { padding-top: #{nth($m, $key)}rem; }
  .pl-#{nth($m, $key) * 10} { padding-left: #{nth($m, $key)}rem; }
  .pr-#{nth($m, $key) * 10} { padding-right: #{nth($m, $key)}rem; }
  .pb-#{nth($m, $key) * 10} { padding-bottom: #{nth($m, $key)}rem; }
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

.ta-c {
  text-align: center;
}

.va-t {
  vertical-align: top;
}

.va-m {
  vertical-align: middle;
}

.va-b {
  vertical-align: bottom;
}

.fl-l {
  float: left;
}

.fl-r {
  float: right;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.pos-f {
  position: fixed;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.transparent {
  @include opacity(0);

  a {
    cursor: default;
    pointer-events: none;
  }
}

.full {
  height: 100%;
  width: 100%;
}

.clearfix {
  clear: both;
}

.ff-g {
  @include ff-g;
}

.ff-m {
  @include ff-m;
}

@for $i from 1 through 8 {
  .col-cs-#{$i} {
    position: relative;
    float: left;
    width: 100% / $i;
  }
}

@keyframes show{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}