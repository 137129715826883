@keyframes header-bg {
  0% {
    opacity: 0;
    transform: scale(1.25);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes header-catch {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    // transform: scale(0.75);
  }
  50% {
    opacity: 0;
    transform: translateY(-100%);
    // transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    // transform: scale(1);
  }
}

@keyframes header-logo {
  0% {
    opacity: 0;
    // transform: scale(0.75);
  }
  25% {
    opacity: 0;
    // transform: scale(1.25);
  }
  100% {
    opacity: 1;
    // transform: scale(1);
  }
}

@keyframes scrolldown {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  75%{
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}